<template>
  <div>
    <headers></headers>
    <div class="contentbox">
      <div class="bg_box">
        <img class="bgs" src="../assets/image/8.png" alt="" />
        <div class="flecCF tiw">
          <div>Combining Advanced Modern Enterprise</div>
          <div>Management Concepts With Strong Professional</div>
          <div>Technical Advant</div>
        </div>
      </div>
      <div class="boxz">
        <div class="tits">SELF OPERATED PRODUCTS</div>
        <div class="tita">
          <div class="aznb">Product Category</div>
        </div>
        <div class="boxy flex">
          <div
            @click="btn(it)"
            class="boxy_item"
            v-for="(it, i) in arr"
            :key="i"
          >
            <div class="big_img">
              <img class="boxy_img" :src="it.image" alt="" />
            </div>
            <div class="boxy_tit">{{ it.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";

export default {
  name: "about",
  components: {
    headers,
    footers,
  },
  data() {
    return {
      arr: [],
    };
  },
  created() {
    this.catList();
  },
  methods: {
    btn(it) {
     
      this.$router.push({ path: "/pro_list", query: { id: it.id } });
    },
    async catList() {
      let res = await this.$http.index.catList();
      if (res.code == 1) {
       
        this.arr = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.big_img {
  overflow: hidden;
  transition: 0.5s;
}
.boxy_img:hover {
  transform: scale(1.2);
}
.boxy {
  // padding: 130px 422px 190px 360px;
  width: 1209px;
  margin: 0 auto;
  padding: 100px 0px 220px 0px;
  flex-wrap: wrap;
  .boxy_item {
    margin-right: 72px;
    margin-bottom: 79px;
    .boxy_tit {
      font-weight: bold;
      font-size: 22px;
      color: #1ba8c9;
      margin-top: 33px;
      text-align: center;
      font-family: "Arial";
    }
    &:nth-child(3n) {
      margin-right: 0px !important;
    }
  }
  .boxy_img {
    width: 354px;
    height: 307px;
    display: block;
    transition: 0.5s;
  }
}
.tits {
  padding: 60px 0px 59px 0px;
  width: 1200px;
  margin: 0 auto;
  font-family: "Variable";
  font-weight: 600;
  font-size: 40px;
  color: #1ba8c9;
}
.tita {
  padding: 35px 0px;
  background: #0c203f;
  font-family: "Variable";
  font-weight: 400;
  font-size: 35px;
  color: #ffffff;
  .aznb {
    width: 1200px;
    margin: 0 auto;
    font-family: "Variable";
  }
}
.bg_box {
  position: relative;
  .tiw {
    font-family: "Arial";
    font-weight: bold;
    font-size: 35px;
    color: #ffffff;
    line-height: 58px;
    position: absolute;
    left: 50%;
    top: 60%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
.bgs {
  width: 100%;
  height: 573px;
}
</style>
