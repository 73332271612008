import { render, staticRenderFns } from "./new_det.vue?vue&type=template&id=77159eb2&scoped=true"
import script from "./new_det.vue?vue&type=script&lang=js"
export * from "./new_det.vue?vue&type=script&lang=js"
import style0 from "./new_det.vue?vue&type=style&index=0&id=77159eb2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77159eb2",
  null
  
)

export default component.exports