<template>
  <div>
    <headers></headers>
    <div class="content contentbox">
      <div class="bg_box">
        <img class="bgs" src="../assets/image/8.png" alt="" />
        <div class="flecCF tiw">
          <div>Combining Advanced Modern Enterprise</div>
          <div>Management Concepts With Strong Professional</div>
          <div>Technical Advant</div>
        </div>
      </div>
      <div class="boxz">
        <div class="voxqw">
          <div class="tits">CONTACT US</div>
          <div class="line"></div>
          <div class="call flexBs">
            <div class="flexCFa">
              <img class="phone_img" src="../assets/image/10.png" alt="" />
              <div class="phone_tit">Phone</div>
              <div class="nymw">
                <a :href="'tel:' + info.telephone" target="_blank">{{
                  info.telephone
                }}</a>
              </div>
            </div>
            <div class="flexCFa">
              <img class="phone_img" src="../assets/image/11.png" alt="" />
              <div class="phone_tit">WhatsApp</div>
              <div class="nymw">
                <a :href="'https://wa.me/' + info.mobile" target="_blank">{{
                  info.mobile
                }}</a>
              </div>
            </div>
            <div class="flexCFa">
              <img class="phone_img" src="../assets/image/12.png" alt="" />
              <div class="phone_tit">Address</div>
              <div class="nymw">
                No.7 Longteng Road,<br />
                {{ info.city }}{{ info.area }},<br />
                {{ info.province }}
              </div>
            </div>
            <div class="flexCFa">
              <img class="phone_img" src="../assets/image/13.png" alt="" />
              <div class="phone_tit">Email</div>
              <div class="nymw">
                <a :href="'mailto:' + info.email" target="_blank">{{
                  info.email
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calls">
        <div class="call_me">
          <div class="alltit">LEAVE A MESSAGE TO GET CUSTOMIZED SERVICES</div>
          <div class="allline"></div>
          <div class="we_tit">
            We provide a complete set of gas compressor solution services,
            including advanced design technology, production and manufacturing.
            installation and commissioning, packaging and transportation, etc.
            Looking forward to your message!
          </div>
          <div class="yure">
            <contants></contants>
          </div>

          <!-- <div class="yure">
            <div class="flex">
              <el-input
                class="ipt"
                v-model="name"
                placeholder="Name"
              ></el-input>
              <el-input
                class="ipt"
                v-model="email"
                placeholder="Email"
              ></el-input>
              <el-input
                class="ipt"
                v-model="whatsApp"
                placeholder="WhatsApp"
              ></el-input>
            </div>
            <div>
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 7 }"
                placeholder="Message"
                v-model="textarea2"
              >
              </el-input>
            </div>
            <div class="flexC bots">
              <div class="i_more" @click="send()">
                <b>SUBMIT </b>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3347.4226261512667!2d117.24315971174532!3d32.96624997347496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35c94dea9fd5048b%3A0xa036aa278c428221!2sAnhui%20Xianqi%20Food%20Science%20and%20Technology%20Co.%2CLtd.!5e0!3m2!1szh-TW!2shk!4v1722494821964!5m2!1szh-TW!2shk"
      width="100%"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import contants from "@/components/contant.vue";

export default {
  name: "about",
  components: {
    headers,
    footers,
    contants,
  },
  data() {
    return {
      info: "",
      name: "",
      email: "",
      whatsApp: "",
      textarea2: "",
    };
  },
  created() {
    this.contents();
  },
  methods: {
    async send() {
      let res = await this.$http.index.diyform({
        remark: this.textarea2,
        uname: this.name,
        mobile: this.whatsApp,
        email: this.email,
      });
      if (res.code == 1) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.textarea2 = "";
        this.name = "";
        this.whatsApp = "";
        this.email = "";
        this.$router.push({ path: "/thanks" });
      }
    },
    async contents() {
      let res = await this.$http.index.content();
      if (res.code == 1) {
       
        this.info = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
  color: #333;
}
.i_more {
  margin-top: 33px;
  width: 274px;
  height: 41px;
  text-align: center;
  line-height: 41px;
  box-sizing: border-box;
  /* background: none; */
  position: relative;
  overflow: hidden;
  /* -webkit-border-radius: 10px; */
  border-radius: 10px;
  // border: 1px solid #1ba8c9;
  transition: 0.5s ease;
  background: #1ba8c9;
  cursor: pointer;
}

.i_more b {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  position: relative;
  z-index: 2;
  transition: 0.5s ease;
  font-family: "Variable";
}

.i_more::before {
  content: "";
  width: 0;
  height: 1000%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #f7f7f7;
  transition: 0.5s ease;
  display: block;
  z-index: 1;
}

.i_more:hover::before {
  width: 180%;
}

.i_more:hover b {
  color: #015197;
}
//在样式表中定义一个 ::placeholder 伪类选择器，指定需要修改的属性和值，例如修改占位符文本颜色为灰色（#888888）。
::v-deep .el-input__inner::placeholder {
  // color: #6bd3dd;
  font-family: "Variable";
  font-weight: 400;
  font-size: 15px;
}
::v-deep .el-textarea__inner::placeholder {
  // color: #6bd3dd;
  font-family: "Variable";
  font-weight: 400;
  font-size: 15px;
}

.yure {
  margin-top: 53px;
}
/deep/.el-textarea__inner {
  border-color: #f7f7f7;
  background: #f7f7f7;
  margin-top: 9px;
}
/deep/ .el-input__inner {
  border-color: #f7f7f7;
  background: #f7f7f7;
  height: 45px;
}
.ipt {
  margin-right: 25px;
  border: none !important;
  &:last-child {
    margin-right: 0px;
  }
}
.calls {
  padding: 88px 0;
  .call_me {
    width: 1408px;
    margin: 0 auto;
    .alltit {
      font-family: "Variable";
      font-weight: 600;
      font-size: 40px;
      color: #333333;
      text-align: center;
    }
    .allline {
      width: 60px;
      height: 3px;
      background: #1ba8c9;
      margin: 28px auto;
    }
    .we_tit {
      font-family: "Variable";
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 24px;
      width: 933px;
      margin: 0 auto;
      text-align: center;
    }
  }
}
.nymw {
  font-family: "Arial";
  font-weight: 400;
  font-size: 19px;
  color: #333333;
  text-align: center;
  line-height: 30px;
}
.flexBs {
  display: flex;
  justify-content: space-between;
}
.flexCFa {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.voxqw {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.boxz {
  padding-top: 64px;
  height: 586px;
  background-image: url("../assets/image/9.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .tits {
    font-family: "Variable";
    font-weight: 600;
    font-size: 40px;
    color: #333333;
    text-align: center;
  }
  .line {
    width: 67px;
    height: 5px;
    background: #1ba8c9;
    // margin-top: 31px;
    margin: 39px auto 0;
  }
  .call {
    margin-top: 125px;
    padding: 0px 401px;
    .phone_img {
      width: 75px;
      height: 72px;
      margin-bottom: 29px;
    }
    .phone_tit {
      font-family: "Arial";
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.bg_box {
  position: relative;
  .tiw {
    font-family: "Arial";
    font-weight: bold;
    font-size: 35px;
    color: #ffffff;
    line-height: 58px;
    position: absolute;
    left: 50%;
    top: 60%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
.bgs {
  width: 100%;
  height: 573px;
}
</style>
