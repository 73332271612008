<template>
  <div>
    <headers></headers>
    <div class="content contentbox">
      <div class="bg_box">
        <img class="bgs" src="../assets/image/15.png" alt="" />
      </div>
      <div class="boxz">
        <div class="tits">
          What finished products can explosive bead capsules be made into and
          under what circumstances are they suitable for
        </div>
        <div class="tita flex">
          <div class="flex titasw">
            <div
              @click="byn(143)"
              :class="curt == 0 ? 'qwea' : ''"
              class="tits_item"
            >
              Xianqi Dynamics
            </div>
            <div
              @click="byn(127)"
              :class="curt == 1 ? 'qwea' : ''"
              class="tits_item"
            >
              Industry dynamics
            </div>
          </div>
        </div>
        <div class="boxy">
          <div
            @click="det(it)"
            class="boxy_item"
            v-for="(it, i) in new_list"
            :key="i"
          >
            <div class="boxy_im">
              <img class="boxy_img" :src="it.image" alt="" />
            </div>
            <div class="sewa">
              <div class="boxy_tle">{{ it.title }}</div>
              <div class="boxy_time">{{ it.create_time }}</div>
              <!-- <div class="boxy_dex">
                {{ it.description }}
              </div> -->
            </div>
          </div>
          <div class="flexC">
            <!-- <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage3"
              :page-size="pagesize"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination> -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pagesize"
              :pager-count="11"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";

export default {
  name: "new",
  components: {
    headers,
    footers,
  },
  data() {
    return {
      curt: 0,
      new_list: [],
      id: "",
      currentPage3: 1,
      page: 1,
      pagesize: 5,
      total: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id == "143") {
      this.curt = 0;
    } else {
      this.curt = 1;
    }
    this.news();
  },
  methods: {
    handleSizeChange(val) {
    
    },
    handleCurrentChange(val) {
      
      this.page = val;
      this.news();
    },
    byn(cid) {
      this.id = cid;
      if (this.id == "143") {
        this.curt = 0;
      } else {
        this.curt = 1;
      }
      this.news();
    },
    async news() {
      let res = await this.$http.index.news({
        cid: this.id,
        page: this.page,
        page_size: this.pagesize,
      });
      if (res.code == 1) {
        this.new_list = res.data.list;
        this.total = Number(res.data.total);
       
      }
    },
    det(it) {
      this.$router.push({ path: "/new_det", query: { id: it.id } });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-icon {
  font-size: 20px !important;
}
/deep/ .number {
  font-size: 20px !important;
}
.boxy {
  width: 1200px;
  margin: 0 auto;
  padding-top: 79px;
  padding-bottom: 276px;
  .boxy_item {
    padding: 12px 41px 24px 41px;
    display: flex;
    align-items: center;
    box-shadow: -2px 12px 45px 1px rgba(27, 168, 201, 0.1);
    border-radius: 20px;
    margin-bottom: 65px;
    cursor: pointer;
    .boxy_im {
      margin-right: 57px;
      width: 184px;
      height: 100px;
      overflow: hidden;
      border-radius: 15px;
      // transition: 0.5s;
      .boxy_img {
        width: 199px;
        height: 108px;
        transition: 0.5s;
      }
      .boxy_img:hover {
        transform: scale(1.2);
      }
    }
    .sewa {
      // width: 1140px;
    }
    .boxy_tle {
      font-family: "Variable";
      font-weight: 400;
      font-size: 24px;
      color: #333333;
    }
    .boxy_time {
      font-family: "Arial";
      font-weight: bold;
      font-size: 15px;
      color: #333333;
      margin-top: 23px;
    }
    .boxy_dex {
      font-family: "Variable";
      font-weight: 400;
      font-size: 28px;
      color: #1ba8c9;
      margin-top: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.tits {
  width: 895px;
  margin: 60px 664px 65px 361px;
  font-family: "Variable";
  font-weight: 600;
  font-size: 30px;
  color: #1ba8c9;
}
.tita {
  .titasw {
    // width: 1500px;
    // margin: 0 auto;
    margin-left: 361px;
  }
  // padding: 0px 422px 0px 272px;
  background: #0c203f;
  font-family: "Variable";
  font-weight: 400;
  font-size: 26px;
  color: #ffffff;
  .tits_item {
    padding: 35px 56px;
    cursor: pointer;
    font-size: 26px;
  }
  .qwea {
    background: #1ba8c9 !important;
  }
}
.bg_box {
  position: relative;
}
.bgs {
  width: 100%;
  height: 574px;
}
</style>
