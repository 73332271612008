var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headers'),_vm._m(0),_c('div',{staticClass:"box"},[_c('div',{staticClass:"box1"},[_c('div',{staticClass:"tits"},[_vm._v(" Xianqi Biological, 20 years of professional processing of all kinds of brand burst bead products we support OEM and ODM. ")]),_c('div',{staticClass:"titm"},[_vm._v("Anhui Xianoi Bioengineering Technology Co., Ltd")]),_c('div',[_c('div',{staticClass:"flex qwe",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.arr),function(it,i){return _c('div',{key:i,staticClass:"q_bow flex"},[_c('div',{staticClass:"imgs"},[(i == 2 || i == 4)?_c('img',{staticClass:"imgs",attrs:{"src":require("../assets/image/23.png"),"alt":""}}):_vm._e(),(i == 1 || i == 3 || i == 5)?_c('img',{staticClass:"imgs",attrs:{"src":require("../assets/image/24.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"flexC x_box"},[_c('div',{staticClass:"nums"},[_vm._v(_vm._s(i + 1))]),_c('div',{staticClass:"aszes"},[_vm._v(_vm._s(it.name))])])])}),0)]),_c('div',{staticClass:"Provide"},[_vm._v(" Provide your product specifications.We will contact you in due course. ")]),_c('el-form',{ref:"dynamicValidateForm",staticClass:"yure",attrs:{"model":_vm.dynamicValidateForm}},[_c('div',{staticClass:"flex",staticStyle:{"width":"100%"}},[_c('el-form-item',{staticClass:"ipt",attrs:{"prop":"name","rules":[
              {
                required: true,
                message: 'Please enter a name',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{attrs:{"placeholder":"Name"},model:{value:(_vm.dynamicValidateForm.name),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "name", $$v)},expression:"dynamicValidateForm.name"}})],1),_c('el-form-item',{staticClass:"ipt",attrs:{"prop":"email","rules":[
              {
                required: true,
                message: 'Please enter your email address',
                trigger: 'blur',
              },
              {
                type: 'email',
                message: 'Please enter the correct email address',
                trigger: ['blur', 'change'],
              },
            ]}},[_c('el-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.dynamicValidateForm.email),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "email", $$v)},expression:"dynamicValidateForm.email"}})],1),_c('el-form-item',{staticClass:"ipt",attrs:{"prop":"whatsApp","rules":[
              {
                required: true,
                message: 'Please enter WhatsApp',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{attrs:{"placeholder":"WhatsApp"},model:{value:(_vm.dynamicValidateForm.whatsApp),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "whatsApp", $$v)},expression:"dynamicValidateForm.whatsApp"}})],1)],1),_c('el-form-item',{attrs:{"span":6,"prop":"desc","rules":[
            {
              required: true,
              message: 'Please enter Taste and Size',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 4, maxRows: 7 },"placeholder":"Taste and size"},model:{value:(_vm.dynamicValidateForm.desc),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "desc", $$v)},expression:"dynamicValidateForm.desc"}})],1),_c('div',{staticClass:"flexC bots"},[_c('div',{staticClass:"i_more",on:{"click":function($event){return _vm.oemform('dynamicValidateForm')}}},[_c('b',[_vm._v("SUBMIT ")])])])],1)],1)]),_c('footers')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content contentbox"},[_c('div',{staticClass:"bg_box"},[_c('img',{staticClass:"bgs",attrs:{"src":require("../assets/image/22.png"),"alt":""}})])])
}]

export { render, staticRenderFns }