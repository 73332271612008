<template>
  <div>
    <headers></headers>
    <div class="content contentbox">
      <div class="bg_box">
        <img class="bgs" src="../assets/image/8.png" alt="" />
        <div class="flecCF tiw">
          <div>Combining Advanced Modern Enterprise</div>
          <div>Management Concepts With Strong Professional</div>
          <div>Technical Advant</div>
        </div>
      </div>

      <div class="boxz">
        <div id="demoItem1">
          <div class="Bioengineering">
            Anhui Xianoi Bioengineering Technology Co., Ltd
          </div>
          <div class="river" style="text-align: justify">
            {{ info.description }}
          </div>
        </div>
        <div id="demoItem2">
          <div class="reality">Enterprise reality</div>
          <div class="flex terprise">
            <div class="terprise_item" v-for="(it, i) in pic" :key="i">
              <div>
                <img class="terprise_img" :src="$imgurl + it" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div id="demoItem3">
          <div class="duction">R&D and Production</div>
          <div class="flex ductions">
            <div class="ductions_item" v-for="(it, i) in rdpudt" :key="i">
              <div class="ds_tit">{{ it.title }}</div>
              <div class="ds_tit_item">
                <img class="ductions_img" :src="it.image" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
var VueScrollTo = require("vue-scrollto");
// import { ref } from "vue";
export default {
  name: "about",
  components: {
    headers,
    footers,
  },
  data() {
    return {
      info: "",
      pic: [],
      rdpudt: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.aboutus();
    this.rdProduct();
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.changNav();
      });
    }, 500);
  },
  methods: {
    // 导航选中效果
    changNav() {
      VueScrollTo.scrollTo(
        document.getElementById("demoItem" + this.id),
        1000,
        {
          offset: -100,
        }
      );
    },
    async aboutus() {
      let res = await this.$http.index.aboutus();
      if (res.code == 1) {
       
        this.info = res.data;
        this.pic = res.data.pics.split(",");
       
      }
    },
    async rdProduct() {
      let res = await this.$http.index.rdProduct();
      if (res.code == 1) {
       
        this.rdpudt = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.boxz {
  width: 1209px;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 200px;
  .Bioengineering {
    font-family: "Variable";
    font-weight: 600;
    font-size: 30px;
    color: #1ba8c9;
  }
  .river {
    font-family: "Arial";
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 37px;
    margin-top: 35px;
  }
  .duction {
    font-family: "Variable";
    margin-top: 153px;
    font-weight: 600;
    font-size: 30px;
    color: #1ba8c9;
  }
  .ductions {
    margin-top: 39px;
    flex-wrap: wrap;
    .ductions_item {
      margin-right: 24px;
      margin-bottom: 18px;
      &:nth-child(3n) {
        margin-right: 0px !important;
      }
      .ds_tit {
        font-family: "Arial";
        font-weight: bold;
        font-size: 20px;
        color: #000000;
      }
      .ds_tit_item {
        overflow: hidden;
        .ductions_img {
          width: 346px;
          height: 255px;
          display: block;
          transition: 0.5s;
        }
        .ductions_img:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .reality {
    margin-top: 131px;
    font-weight: 600;
    font-size: 30px;
    color: #1ba8c9;
    font-family: "Variable";
  }
  .terprise {
    margin-top: 40px;
    flex-wrap: wrap;
    .terprise_item {
      margin-right: 24px;
      margin-bottom: 15px;
      &:nth-child(3n) {
        margin-right: 0px !important;
      }
      overflow: hidden;
      .terprise_img {
        width: 387px;
        height: 257px;
        display: block;
        cursor: pointer;

        transition: 0.5s;
      }
      .terprise_img:hover {
        transform: scale(1.2);
      }
    }
  }
}
.bg_box {
  position: relative;
  .tiw {
    font-family: "Arial";
    font-weight: bold;
    font-size: 35px;
    color: #ffffff;
    line-height: 58px;
    position: absolute;
    left: 50%;
    top: 60%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
.bgs {
  width: 100%;
  height: 573px;
}
</style>
