<template>
  <div>
    <headers></headers>
    <div class="content contentbox">
      <div style="position: relative">
        <el-carousel indicator-position="none" :interval="5000" arrow="always">
          <el-carousel-item class="imgr" v-for="(item, i) in bananer" :key="i">
            <img class="imgs" style="width: 100%" :src="item.image" alt="" />
            <div class="i_btn" @click="go_web(item.url)">
              <div class="i_more">
                <b>Learn More </b>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="contant our">
        <div class="flexB ours_one">
          <div class="our_t">OUR PRODUCTS</div>
          <div class="our_rt">
            Dedicated to the research and development, production, and sales of
            new capsule technology products, to achieve sustainable development
            of green economy.
          </div>
        </div>
        <div class="swipers">
          <div class="swipers_item">
            <swiper ref="mySwiper" :options="swiperOptions">
              <!-- 轮播内容 -->
              <swiper-slide
                v-for="(it, i) in product_list"
                :key="i"
                :class="slideClasses(i)"
              >
                <div @click="product_listopen(it)">
                  <div class="big_img">
                    <img class="imgs" :src="it.image" alt="" />
                  </div>

                  <div class="qwaw">
                    <div class="Food">{{ it.title }}</div>
                    <div class="tiwa" style="text-align: justify">
                      {{ it.description }}
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <!-- 左右箭头 -->
              <div class="btn_e swiper-button-prev" slot="button-prev">
                <img class="btn_e_img" src="../assets/image/2.png" alt="" />
              </div>
              <div class="btn_e swiper-button-next" slot="button-next">
                <img class="btn_e_img" src="../assets/image/3.png" alt="" />
              </div>

              <!-- 分页器 -->
              <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            </swiper>
          </div>
        </div>
      </div>
      <div class="advants">
        <div class="contant">
          <div class="flexCF">
            <div class="ges">OUR ADVANTAGES</div>
            <div class="line"></div>
          </div>
          <div class="flex countw">
            <div class="countw_item flexCF">
              <div class="flex sde">
                <countTo
                  separator=""
                  :useEasing="true"
                  :startVal="startVal"
                  :endVal="numArray[0]"
                  :duration="3000"
                ></countTo>
              </div>
              <div class="linwa"></div>
              <div class="est">Established in Bengbu City</div>
            </div>
            <div class="countw_item flexCF">
              <div class="flex sde">
                <countTo
                  separator=""
                  :useEasing="true"
                  :startVal="startVal"
                  :endVal="numArray[1]"
                  :duration="3000"
                ></countTo>
                <div class="unit">m²+</div>
              </div>
              <div class="linwa"></div>
              <div class="est">Floor space</div>
            </div>
            <div class="countw_item flexCF">
              <div class="flex sde">
                <countTo
                  separator=""
                  :useEasing="true"
                  :startVal="startVal"
                  :endVal="numArray[2]"
                  :duration="3000"
                ></countTo>
                <div class="unit">kinds +</div>
              </div>
              <div class="linwa"></div>
              <div class="est">Application field</div>
            </div>
            <div class="countw_item flexCF">
              <div class="sde">
                <countTo
                  separator=""
                  :useEasing="true"
                  :startVal="startVal"
                  :endVal="numArray[3]"
                  :duration="3000"
                ></countTo>
                <div class="unit">companies+</div>
              </div>
              <div class="linwa"></div>
              <div class="est">Sign a long-term cooperation agreement</div>
            </div>
          </div>
          <div class="anhi">
            <div class="views flexB">
              <div class="desx" style="text-align: justify">
                {{ info.description }}
              </div>
              <div @click="openvideo" class="videoq flexC">
                <img class="videoqs" :src="info.image" alt="" />
                <img class="videoq_img" src="../assets/image/6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advants_COMPANY">
        <div class="contant">
          <div class="coms">
            <div class="dxca">
              <div class="compa">COMPANY DYNAMICS</div>
              <div class="compa_r">Xianqi Dynamic Industry Dynamics</div>
            </div>
            <div class="qaz flexC">
              <div
                @click="zidet(it)"
                class="qaz_one"
                v-for="(it, i) in new_list"
                :key="i"
              >
                <div class="qaz_twos">
                  <div class="times">{{ it.addtime }}</div>
                  <div class="big_imgs">
                    <img class="qzou" :src="it.image" alt="" />
                  </div>
                </div>
                <div class="q_tit">
                  <div class="q_tits">
                    <div class="it_tit">{{ it.title }}</div>
                    <div class="m_tit">
                      {{ it.description }}
                    </div>
                    <div class="flex imf">
                      <img class="imf_img" src="../assets/image/3.png" alt="" />
                      <div class="imf_tit">Discover more</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="allcontants">
            <contants></contants>
          </div> -->
        </div>
      </div>
    </div>

    <el-dialog
      top="25vh"
      :modal="false"
      title
      :visible="isShow"
      width="40%"
      @close="closeDialog"
    >
      <video
        :src="videoUrl"
        controls
        autoplay
        class="video"
        width="100%"
      ></video>
    </el-dialog>

    <footers></footers>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import countTo from "vue-count-to";
import contants from "@/components/contant.vue";

export default {
  name: "Home",
  components: {
    countTo,
    swiper,
    swiperSlide,
    headers,
    footers,
    contants,
  },
  data() {
    return {
      weburl: "",
      isShow: false,
      // 测试地址
      // videoUrl: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4'
      videoUrl: "",
      videoUrlall: "",

      new_list: [],
      numArray: [],
      info: "",
      bananer: [],
      product_list: [],
      //数字开始
      startVal: 0,
      //数字结束
      endVal: 50000,
      swiperOptions: {
        //loop: true,
        slidesPerView: 5,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  created() {
    this.banner();
    this.product();
    this.aboutus();
    this.news();
    this.alogos();
  },
  methods: {
    slideClasses(index) {
      // 根据轮播项的索引来决定是否应用特定的类名
      return {
        "swiper-slide-active": index === this.$refs.mySwiper.swiper.activeIndex,
        "swiper-slide-active5":
          index === this.$refs.mySwiper.swiper.activeIndex + 4,
        "swiper-slide-active1":
          index === this.$refs.mySwiper.swiper.activeIndex + 1,
        "swiper-slide-active2":
          index === this.$refs.mySwiper.swiper.activeIndex + 2,
        "swiper-slide-active3":
          index === this.$refs.mySwiper.swiper.activeIndex + 3,
        // "swiper-slide-next":
        //   index === this.$refs.mySwiper.swiper.activeIndex + 2 ||
        //   (this.$refs.mySwiper.swiper.isBeginning &&
        //     index === this.slides.length - 2),
        // 可以根据需要添加更多的类名判断逻辑
      };
    },
    async alogos() {
      let res = await this.$http.index.logo({});
      if (res.code == 1) {
        this.weburl = res.data.banner_url;
      }
    },
    go_web(url) {
      location.href = url;
    },
    product_listopen(it) {
    
      this.$router.push({ path: "/pro_det", query: { id: it.id } });
    },
    zidet(it) {
      this.$router.push({ path: "/new_det", query: { id: it.id } });
    },
    openvideo() {
      this.isShow = true;
      this.videoUrl = this.videoUrlall;
    },
    closeDialog() {
      // 关闭弹框
      this.isShow = false;
      this.videoUrl = ""; // 清空数据 关闭视频播放
    },

    async news() {
      let res = await this.$http.index.news();
      if (res.code == 1) {
        this.new_list = res.data.list.splice(0, 3);
       
      }
    },
    async aboutus() {
      let res = await this.$http.index.aboutus();
      if (res.code == 1) {
       
        this.info = res.data;
        // Step 1: Split the string into an array of strings
        let strArray = res.data.tag.split(",");
        // Step 2: Convert each string element to a number
        this.numArray = strArray.map((item) => parseInt(item));
        this.videoUrlall = this.$imgurl + res.data.video;
       
      }
    },
    async product() {
      let res = await this.$http.index.product();
      if (res.code == 1) {
        this.product_list = res.data.list;
      }
    },
    async banner() {
      let res = await this.$http.index.banner();
      if (res.code == 1) {
       
        this.bananer = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.allcontants {
  margin-top: 100px;
}
.swiper-slide-active {
  transition: transform 0.5s ease;
  margin-right: 0px !important;
  transform: scale(0.8); /* 左右两侧的卡片缩小 */
}

.swiper-slide-active5 {
  transition: transform 0.5s ease;

  transform: scale(0.8); /* 左右两侧的卡片缩小 */
}
.swiper-slide-active1 {
  margin-right: 15px !important;
}
.swiper-slide-active2 {
  margin-right: 15px !important;
}
// .swiper-slide-active3 {
//   margin-right: 20px !important;
// }
.btn_e_img {
  width: 42px;
  height: 42px;
}
.btn_e {
  width: 42px;
  height: 42px;
}
/deep/ .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: none !important;
}
/deep/.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: none !important;
}
/deep/ .swipers_item {
  height: 500px !important;
}
.i_btn {
  position: absolute;
  bottom: 200px;
  z-index: 88;
  left: 50%;
  transform: translateX(-50%);
}
.i_more {
  width: 151px;
  height: 39px;
  text-align: center;
  line-height: 39px;
  box-sizing: border-box;
  /* background: none; */
  position: relative;
  overflow: hidden;
  /* -webkit-border-radius: 10px; */
  border-radius: 10px;
  // border: 1px solid #1ba8c9;
  transition: 0.5s ease;
  background: #1ba8c9;
  cursor: pointer;
}

.i_more b {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  position: relative;
  z-index: 2;
  transition: 0.5s ease;
  font-family: "Variable";
}

.i_more::before {
  content: "";
  width: 0;
  height: 1000%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #fff;
  transition: 0.5s ease;
  display: block;
  z-index: 1;
}

.i_more:hover::before {
  width: 180%;
}

.i_more:hover b {
  color: #015197;
}

/deep/.el-carousel__arrow i {
  font-size: 45px;
}
::v-deep .el-dialog__headerbtn {
  top: -75px;
  right: -40px;
  padding: 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 45px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transform: translateX(50%);
  background-color: rgba(107, 107, 107, 0.5);
}
::v-deep .el-dialog__header {
  background: transparent;
  height: 0;
  padding: 0;
}
::v-deep .el-dialog__body {
  background: transparent;
  height: 0;
  padding: 0;
}

/deep/ .el-carousel__arrow {
  background: transparent !important;
}
.coms {
  // padding: 0px 95px;
  .qaz {
    width: 100%;
    margin-top: 90px;
    .qaz_one::after {
      content: "";
      width: 100%;
      height: 0px;
      // border-radius: 10px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        transparent,
        rgba(1, 81, 151, 0.1)
      );
      transition: all 0.5s ease;
      // background: #f00;
    }
    .qaz_one:hover::after {
      height: 20%;
    }
    .qaz_one {
      transition: all 0.5s ease;
      flex: 1;
      margin-right: 39px;
      cursor: pointer;
      position: relative;
      &:last-child {
        margin-right: 0px;
      }
      .q_tit {
        padding: 32px 0 0px;
        height: 190px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .q_tits {
        }
        .it_tit {
          font-family: "Arial";
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          width: 311px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .m_tit {
          width: 311px;
          margin-top: 24px;
          font-family: Arial;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          // line-height: 23px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          white-space: normal;
          word-break: break-word;
          -webkit-line-clamp: 3;
        }
        .imf {
          margin-top: 28px;
          .imf_img {
            width: 16px;
            height: 16px;
          }
          .imf_tit {
            font-family: "Arial";
            font-weight: 400;
            font-size: 16px;
            color: #1ba8c9;
            margin-left: 10px;
          }
        }
      }
      .qaz_twos {
        position: relative;
        height: 205px;
        overflow: hidden;
        transition: 0.5s;
        .times {
          position: absolute;
          left: 0;
          top: 0;
          width: 133px;
          height: 39px;
          font-size: 14px;
          color: #ffffff;
          text-align: center;
          line-height: 39px;
          background: #1ba8c9;
          border-end-end-radius: 50px;
          z-index: 20;
          font-family: "Arial";
        }
        .big_imgs {
          width: 374px;
          height: 205px;
          overflow: hidden;

          img:hover {
            transform: scale(1.2);
          }
          img {
            width: 100%;
            height: 100%;
            transition: 0.5s;
          }
        }

        // width: 100%;
      }
    }
  }
  .dxca {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .compa {
      font-family: "Variable";
      font-weight: 600;
      font-size: 30px;
      color: #333333;
    }
    .compa_r {
      font-family: "Arial";
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
  }
}
.anhi:hover {
  box-shadow: -3px 15px 107px 2px rgba(27, 168, 201, 0.12);
}
.anhi {
  margin-top: 61px;

  // padding: 0px 36px 80px;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .views {
    cursor: pointer;
    padding: 51px 38px;
    background: #fff;
    border-radius: 20px;
    .desx {
      width: 629px;
      height: 324px;
      font-family: "Arial";
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 28px;
      overflow: hidden;
    }
    .videoq {
      margin-left: 62px;
      width: 444px;
      height: 301px;
      position: relative;
      background: #000;
      border-top-left-radius: 60px;
      overflow: hidden;
      .videoqs {
        width: 100%;
        height: 376px;
      }
      .videoq_img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 72px;
        height: 72px;
      }
    }
  }
}
.est {
  font-family: "Arial";
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-top: 19px;
}
.sde {
  font-family: "COUTURE";
  font-weight: bold;
  font-size: 48px;
  color: #333333;
  display: flex;
  align-items: flex-end;
}
.countw {
  margin-top: 60px;
  .countw_item {
    flex: 1;
    .unit {
      font-family: "Variable";
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
    }
  }
}
.linwa {
  width: 33px;
  height: 4px;
  background: #1ba8c9;
  margin-top: 21px;
}
.ges {
  font-family: "Variable";
  font-weight: 600;
  font-size: 30px;
  color: #333333;
}
.line {
  width: 86px;
  height: 6px;
  background: #1ba8c9;
  margin-top: 23px;
}
.advants {
  background: #f5f5f5;
  padding-top: 72px;
}
.qwaw {
  background: #1ba8c9;
  padding: 25px 33px 0;
  height: 179px;
  .Food {
    font-family: "Arial-Bold";

    font-size: 16px;
    color: #ffffff;
    text-align: center;
  }
  .tiwa {
    margin-top: 7px;
    font-family: "Variable";
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; /* 指定显示文本的行数 */
    overflow: hidden; /* 超出隐藏 */

    font-size: 14px;
    color: #ffffff;
  }
}
.swiper-button-prev {
  position: absolute;
  top: 40%;
  left: -50px;
  transform: translateX(-50%);
}
.swiper-button-next {
  position: absolute;
  top: 40%;
  right: -100px;

  transform: translateX(-50%);
}
.l_img {
  width: 43px;
  height: 43px;
}
.swipers {
  margin-top: 125px;

  position: relative;
  .swipers_item {
    width: 100%;
    overflow: hidden;
  }
}
.our {
  padding: 61px 0px 111px;
  .ours_one {
    // padding: 0px 62px;
    .our_t {
      font-family: "Variable";
      font-weight: 600;
      font-size: 50px;
      color: #333333;
    }
    .our_rt {
      width: 546px;
      font-family: "Arial";
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 25px;
    }
  }
}
.dote {
  width: 300px;
  height: 200px;
  box-shadow: 0 0 83px 0 rgba(216, 216, 216, 0.97);
}
.imgr {
  height: 919px;
}
.big_img {
  overflow: hidden;
  transition: 0.5s;
  .imgs {
    width: 100%;
    display: block;
    transition: 0.5s;
    cursor: pointer;
  }
  .imgs:hover {
    transform: scale(1.2);
  }
}
.advants_COMPANY {
  background: #f5f5f5;
  padding-top: 165px;
}
/deep/ .el-carousel__container {
  height: 919px;
}
/deep/ .swipers_item {
  height: 426px !important;
}
</style>
