<template>
  <div>
    <headers></headers>
    <div class="content contentbox">
      <div class="bg_box">
        <img class="bgs" src="../assets/image/22.png" alt="" />
      </div>
    </div>
    <div class="box">
      <div class="box1">
        <div class="tits">
          Xianqi Biological, 20 years of professional processing of all kinds of
          brand burst bead products we support OEM and ODM.
        </div>
        <div class="titm">Anhui Xianoi Bioengineering Technology Co., Ltd</div>
        <div>
          <div class="flex qwe" style="flex-wrap: wrap">
            <div class="q_bow flex" v-for="(it, i) in arr" :key="i">
              <div class="imgs">
                <img
                  v-if="i == 2 || i == 4"
                  class="imgs"
                  src="../assets/image/23.png"
                  alt=""
                />
                <img
                  v-if="i == 1 || i == 3 || i == 5"
                  class="imgs"
                  src="../assets/image/24.png"
                  alt=""
                />
              </div>

              <div class="flexC x_box">
                <div class="nums">{{ i + 1 }}</div>
                <div class="aszes">{{ it.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="Provide">
          Provide your product specifications.We will contact you in due course.
        </div>

        <el-form
          class="yure"
          :model="dynamicValidateForm"
          ref="dynamicValidateForm"
        >
          <div class="flex" style="width: 100%">
            <!-- 名称 -->
            <el-form-item
              class="ipt"
              prop="name"
              :rules="[
                {
                  required: true,
                  message: 'Please enter a name',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="dynamicValidateForm.name"
                placeholder="Name"
              ></el-input>
            </el-form-item>
            <!-- 邮箱 -->
            <el-form-item
              class="ipt"
              prop="email"
              :rules="[
                {
                  required: true,
                  message: 'Please enter your email address',
                  trigger: 'blur',
                },
                {
                  type: 'email',
                  message: 'Please enter the correct email address',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                placeholder="Email"
                v-model="dynamicValidateForm.email"
              ></el-input>
            </el-form-item>

            <!-- whatsApp -->
            <el-form-item
              class="ipt"
              prop="whatsApp"
              :rules="[
                {
                  required: true,
                  message: 'Please enter WhatsApp',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="dynamicValidateForm.whatsApp"
                placeholder="WhatsApp"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item
            :span="6"
            prop="desc"
            :rules="[
              {
                required: true,
                message: 'Please enter Taste and Size',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 7 }"
              placeholder="Taste and size"
              v-model="dynamicValidateForm.desc"
            >
            </el-input>
          </el-form-item>
          <div class="flexC bots">
            <div class="i_more" @click="oemform('dynamicValidateForm')">
              <b>SUBMIT </b>
            </div>
          </div>
        </el-form>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";

export default {
  name: "about",
  components: {
    headers,
    footers,
  },
  data() {
    return {
      dynamicValidateForm: {
        // email: "",
        name: "",
        email: "",
        whatsApp: "",
        desc: "",
      },
      name: "",
      email: "",
      whatsApp: "",
      desc: "",
      arr: [
        { name: "Communicate taste and size" },
        { name: "Send raw materials or we debug raw materials reagents" },
        { name: "Make a burst bead sample" },
        { name: "Sign the contract pay the money" },
        { name: "Finished product" },
        { name: "Delivery of goods" },
      ],
    };
  },
  created() {},
  methods: {
    async oemform(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.adds();
        } else {
        
          return false;
        }
      });
    },

    async adds() {
      let formName = this.dynamicValidateForm;
      let res = await this.$http.index.oemform({
        desc: formName.desc,
        username: formName.name,
        source: formName.whatsApp,
        uemail: formName.email,
      });
      if (res.code == 1) {
        this.$message({
          message: "success",
          type: "success",
        });
        this.dynamicValidateForm.desc = "";
        this.dynamicValidateForm.name = "";
        this.dynamicValidateForm.whatsApp = "";
        this.dynamicValidateForm.email = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bots {
  margin-top: 23px;
}
.i_more {
  width: 274px;
  height: 41px;
  text-align: center;
  line-height: 41px;
  box-sizing: border-box;
  /* background: none; */
  position: relative;
  overflow: hidden;
  /* -webkit-border-radius: 10px; */
  border-radius: 10px;
  // border: 1px solid #1ba8c9;
  transition: 0.5s ease;
  background: #1ba8c9;
  cursor: pointer;
}

.i_more b {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  position: relative;
  z-index: 2;
  transition: 0.5s ease;
  font-family: "Variable";
}

.i_more::before {
  content: "";
  width: 0;
  height: 1000%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #fff;
  transition: 0.5s ease;
  display: block;
  z-index: 1;
}

.i_more:hover::before {
  width: 180%;
}

.i_more:hover b {
  color: #015197;
}
//在样式表中定义一个 ::placeholder 伪类选择器，指定需要修改的属性和值，例如修改占位符文本颜色为灰色（#888888）。
::v-deep .el-input__inner::placeholder {
  color: #6bd3dd;
  font-family: "Variable";
  font-weight: 400;
  font-size: 15px;
}
::v-deep .el-textarea__inner::placeholder {
  color: #6bd3dd;
  font-family: "Variable";
  font-weight: 400;
  font-size: 15px;
}

.yure {
  margin-top: 53px;
}
/deep/.el-textarea__inner {
  border-color: #f7f7f7;
  background: #f7f7f7;
  margin-top: 9px;
}
/deep/ .el-input__inner {
  border-color: #f7f7f7;
  background: #f7f7f7;
  height: 45px;
}
.ipt {
  margin-right: 25px;
  border: none !important;
  flex: 1;
  &:last-child {
    margin-right: 0px;
  }
}
.Provide {
  font-family: "Variable";
  font-weight: 600;
  font-size: 36px;
  color: #333333;
  line-height: 50px;
  margin-top: 172px;
}
.qwe {
  margin-left: 11px;
}
.q_bow {
  margin-top: 75px;
  &:nth-child(2) {
    .x_box {
      background: #13b8d3 !important;
    }
  }
  &:nth-child(3) {
    .x_box {
      background: #13b8d3 !important;
    }
  }
  // width: 449px;
  &:nth-child(2n) {
    margin-left: 25px;
    .x_box {
      width: 505px !important;
      .aszes {
        width: 388px;
      }
    }
  }
  .x_box {
    width: 449px;
    height: 92px;
    background: #6bd3dd;
    border-radius: 7px;
    margin-left: 25px;
    .nums {
      font-family: "Variable";
      font-weight: 600;
      font-size: 43px;
      color: #feffff;
      margin-right: 36px;
    }
    .aszes {
      font-family: "Arial";
      font-weight: 400;
      font-size: 26px;
      color: #170909;
    }
  }
}
.imgs {
  width: 21.4px;
  height: 41.9px;
}
.box {
  background: #ffffff;
  width: 1202px;
  margin: 0 auto;
  .box1 {
    padding: 74px 0px 134px;
    .titm {
      font-family: "Variable";
      font-weight: 600;
      font-size: 35px;
      color: #1ba8c9;
      line-height: 69px;
      margin-left: 60px;
      margin-top: 153px;
    }
    .tits {
      width: 1150px;
      font-family: "Variable";
      font-weight: 600;
      font-size: 36px;
      color: #000000;
      line-height: 54px;
      margin-left: 15px;
    }
  }
}
.bg_box {
  position: relative;
  .tiw {
    font-family: "Arial";
    font-weight: bold;
    font-size: 35px;
    color: #ffffff;
    line-height: 58px;
    position: absolute;
    left: 50%;
    top: 60%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
.bgs {
  width: 100%;
  height: 573px;
  display: block;
}
</style>
