<template>
  <div>
    <headers></headers>
    <div class="content contentbox">
      <div class="bg_box">
        <img class="bgs" src="../assets/image/21.png" alt="" />
        <div class="flecCF tiw">
          <div>Combining Advanced Modern Enterprise</div>
          <div>Management Concepts With Strong Professional</div>
          <div>Technical Advant</div>
        </div>
      </div>
      <div class="boxz">
        <div class="tits">SELF OPERATED PRODUCTS</div>
        <div class="tita flex">
          <div class="flex titasw">
            <div
              @click="byn(it)"
              :class="curt == it.id ? 'qwea' : ''"
              class="tits_item"
              v-for="(it, i) in arr"
              :key="i"
            >
              {{ it.name }}
            </div>
          </div>
        </div>
        <div class="boxy">
          <!-- <div
            @click="det(it)"
            class="boxy_item"
            v-for="(it, i) in new_list"
            :key="i"
          >
            <div class="boxy_im">
              <img class="boxy_img" :src="it.image" alt="" />
            </div>
            <div class="sewa">
              <div class="boxy_tle">{{ it.title }}</div>
              <div class="boxy_time">{{ it.create_time }}</div>
            </div>
          </div> -->
          <div class="p_arr flex">
            <div
              @click="det(it)"
              v-for="(it, i) in new_list"
              :key="i"
              class="arr_it"
            >
              <img :src="it.image" alt="" />
              <div class="q_box">
                <div class="arr_tit">{{ it.title }}</div>
                <div class="line"></div>
              </div>
            </div>
            <div class="flexC" style="width: 100%">
              <el-empty
                v-if="new_list.length == 0"
                :image-size="200"
              ></el-empty>
            </div>
          </div>
          <div class="flexC">
            <!-- <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage3"
              :page-size="pagesize"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination> -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pagesize"
              :pager-count="11"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";

export default {
  name: "new",
  components: {
    headers,
    footers,
  },
  data() {
    return {
      curt: 0,
      new_list: [],
      id: "",
      currentPage3: 1,
      page: 1,
      pagesize: 5,
      total: null,
      arr: [],
    };
  },
  created() {
    this.curt = this.$route.query.id;
    // if (this.id == "143") {
    //   this.curt = 0;
    // } else {
    //   this.curt = 1;
    // }
    // this.news();

    this.catList();
    this.productList();
  },
  methods: {
    //  产品列表
    async catList() {
      let res = await this.$http.index.catList();
      if (res.code == 1) {
       
        this.arr = res.data;
      }
    },
    async productList() {
      let res = await this.$http.index.productList({
        cat_id: this.curt,
        page: this.page,
        pagesize: this.pagesize,
      });
      if (res.code == 1) {
       
        this.new_list = res.data.list;
        this.total = Number(res.data.total);
      }
    },
    handleSizeChange(val) {
     
    },
    handleCurrentChange(val) {
     
      this.page = val;
      this.productList();
    },
    byn(it) {
      this.curt = it.id;
      this.page = 1;
      this.productList();
    },

    det(it) {
      this.$router.push({ path: "/pro_det", query: { id: it.id } });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-icon {
  font-size: 20px !important;
}
/deep/ .number {
  font-size: 20px !important;
}
.boxy {
  width: 1200px;
  margin: 0 auto;
  padding-top: 98px;
  padding-bottom: 229px;
  //
  .p_arr {
    flex-wrap: wrap;
    .arr_it {
      width: 232px;
      background: #ffffff;
      margin-right: 48px;
      margin-bottom: 45px;
      box-shadow: -2px 12px 45px 1px rgba(27, 168, 201, 0.1);
      border-radius: 33px;
      img {
        width: 232px;
        height: 199px;
        display: block;
      }
      .q_box {
        padding: 45px 15px 43px;
        .arr_tit {
          font-family: "Arial";
          font-weight: bold;
          font-size: 16px;
          color: #1ba8c9;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .line {
          width: 43px;
          height: 3px;
          background: #e8e8e8;
          margin: 19px auto 0;
        }
      }
    }
  }
}
.tits {
  width: 1209px;
  padding: 60px 0px 57px 0px;
  font-family: "Variable";
  font-weight: 600;
  font-size: 30px;
  color: #1ba8c9;
  margin: 0 auto;
}
.tita {
  .titasw {
    // width: 1500px;
    // margin: 0 auto;
    margin-left: 361px;
  }
  // padding: 0px 422px 0px 272px;
  background: #0c203f;
  font-family: "Variable";
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  .tits_item {
    padding: 31px 19px;
    cursor: pointer;
    font-size: 16px;
  }
  .qwea {
    background: #1ba8c9 !important;
  }
}
.bg_box {
  position: relative;
  .tiw {
    font-family: "Arial";
    font-weight: bold;
    font-size: 35px;
    color: #ffffff;
    line-height: 58px;
    position: absolute;
    left: 50%;
    top: 60%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
.bgs {
  width: 100%;
  height: 573px;
}
</style>
