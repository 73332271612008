<template>
  <div>
    <headers></headers>
    <div class="content contentbox">
      <div class="bg_box">
        <img class="bgs" src="../assets/image/16.png" alt="" />
        <!-- <div class="flecCF tiw">
          <div>Combining Advanced Modern Enterprise</div>
          <div>Management Concepts With Strong Professional</div>
          <div>Technical Advant</div>
        </div> -->
      </div>
      <div class="boxz">
        <div class="ttis flex">
          <div
            :class="ids == it.id ? 'itemcur' : ''"
            @click="choose(it, i)"
            v-for="(it, i) in arr"
            :key="i"
            class="itemw"
          >
            {{ it.name }}
          </div>
        </div>
        <div class="tits">{{ infos.title }}</div>
        <div class="artice" v-html="infos.content"></div>
        <!-- <div class="artice">1111</div> -->
        <!-- <div class="woprs">
          <div>
            <img
              @click="more"
              class="byn"
              src="../assets/image/18.png"
              alt=""
            />
          </div>
          <div>
            <img class="senve" :src="infos.image" alt="" />
          </div>
        </div> -->
        <!-- <contants></contants> -->
      </div>
      <!-- <div style="background: #f5f5f5">
        <div class="videow flexC" @click="openvideo">
          <img class="videow" :src="$imgurl + infos.video_img" alt="" />
          <img class="v_img" src="../assets/image/19.png" alt="" />
        </div>
      </div> -->
      <!-- <div class="fotg" style="background: #fff"></div> -->
    </div>
    <el-dialog
      :modal="false"
      top="25vh"
      title
      :visible="isShow"
      width="40%"
      @close="closeDialog"
    >
      <video
        :src="videoUrl"
        controls
        autoplay
        class="video"
        width="100%"
      ></video>
    </el-dialog>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import contants from "@/components/contant.vue";
export default {
  name: "about",
  components: {
    headers,
    footers,
    contants,
  },
  data() {
    return {
      ids: "",
      arr: [],
      id: "",
      infos: "",
      isShow: false,
      // 测试地址
      // videoUrl: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4'
      videoUrl: "",
      videoUrlall: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.catList();
  },
  methods: {
    more() {
      this.$router.push({ path: "/product" });
    },
    choose(it) {
      // this.id = it.id;
      // this.productInfo();
      this.$router.push({ path: "/pro_list", query: { id: it.id } });
    },
    openvideo() {
      this.isShow = true;
      this.videoUrl = this.videoUrlall;
    },
    closeDialog() {
      // 关闭弹框
      this.isShow = false;
      this.videoUrl = ""; // 清空数据 关闭视频播放
    },

    async catList() {
      let res = await this.$http.index.catList();
      if (res.code == 1) {
        this.arr = res.data;
        this.productInfo();
      }
    },
    async productInfo() {
      let res = await this.$http.index.productInfo({
        id: this.id,
      });
      if (res.code == 1) {
        this.infos = res.data;
        this.videoUrlall = this.$imgurl + res.data.video;
        this.ids = res.data.cid;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fotg {
  background: #fff;
  height: 221px;
}
::v-deep .el-dialog__headerbtn {
  top: -75px;
  right: -40px;
  padding: 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 45px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transform: translateX(50%);
  background-color: rgba(107, 107, 107, 0.5);
}
::v-deep .el-dialog__header {
  background: transparent;
  height: 0;
  padding: 0;
}
::v-deep .el-dialog__body {
  background: transparent;
  height: 0;
  padding: 0;
}

.videow {
  width: 1209px;
  margin: 0 auto;
  height: 411px;
  // background: #1a1a1a;
  position: relative;
  .v_img {
    width: 56px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.woprs {
  display: flex;
  justify-content: space-between;
  margin-top: 37px;
}
.byn {
  width: 159px;
  height: 54px;
  padding-top: 64px;
}
.senve {
  width: 1238px;
  height: 742px;
}
.boxz {
  width: 1209px;
  margin: 0 auto;
  padding: 60px 0px 120px;
  .tits {
    margin-top: 138px;
    font-family: "Arial";
    font-weight: bold;
    font-size: 50px;
    color: #000000;
  }
  .ttis {
    .itemw {
      font-family: "Variable";
      text-align: center;
      flex: 1;
      margin-right: 19px;
      font-weight: 400;
      font-size: 15px;
      color: #000000;
      padding: 20px;
      background: #f3f3f3;
      border-radius: 8px;
      white-space: nowrap;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
    .itemcur {
      background: #1ba8c9;
      color: #ffffff;
    }
  }
  .artice {
    margin-top: 94px;
    font-family: "Arial";
    font-weight: 400;
    font-size: 21px;
    color: #333333;
    line-height: 32px;
  }
}
.bg_box {
  position: relative;
  .tiw {
    font-family: "Arial";
    font-weight: bold;
    font-size: 35px;
    color: #ffffff;
    line-height: 58px;
    position: absolute;
    left: 30%;
    top: 60%;
    // text-align: center;
    transform: translate(-50%, -50%);
  }
}
.bgs {
  width: 100%;
  height: 573px;
}
</style>
