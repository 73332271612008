<template>
  <div>
    <headers></headers>
    <div class="content contentbox">
      <div class="bg_box">
        <img class="bgs" src="../assets/image/8.png" alt="" />
        <div class="flecCF tiw">
          <div>Combining Advanced Modern Enterprise</div>
          <div>Management Concepts With Strong Professional</div>
          <div>Technical Advant</div>
        </div>
      </div>

      <div class="boxz">
        <div class="boxqa flexC">
          <div class="you">Thank you for your submission！</div>
          <a href="/"> <div class="home">Home</div></a>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";

export default {
  name: "about",
  components: {
    headers,
    footers,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
  color: #fff;
}
.boxqa {
  margin: 80px 0px 250px;
  flex-direction: column;
  .you {
    font-size: 40px;
    color: #222;
    font-weight: bold;
  }
  .home {
    display: inline-block;
    padding: 0 60px;
    background: #1ba8c9;
    line-height: 60px;
    font-size: 20px;
    color: #fff;
    margin-top: 40px;
    border-radius: 10px;
  }
}
.boxz {
  width: 1209px;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 200px;
}
.bg_box {
  position: relative;
  .tiw {
    font-family: "Arial";
    font-weight: bold;
    font-size: 35px;
    color: #ffffff;
    line-height: 58px;
    position: absolute;
    left: 50%;
    top: 60%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
.bgs {
  width: 100%;
  height: 573px;
}
</style>
