<template>
  <div>
    <headers></headers>
    <div class="content contentbox">
      <div class="bg_box">
        <img class="bgs" src="../assets/image/14.png" alt="" />
        <div class="flecCF tiw">
          <div>Combining Advanced Modern Enterprise</div>
          <div>Management Concepts With Strong Professional</div>
          <div>Technical Advant</div>
        </div>
      </div>
      <div class="boxz">
        <div class="ttis">
          {{ info.title }}
        </div>
        <div class="artice" v-html="info.content"></div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";

export default {
  name: "about",
  components: {
    headers,
    footers,
  },
  data() {
    return {
      id: "",
      info: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.newsInfo();
  },
  methods: {
    async newsInfo() {
      let res = await this.$http.index.newsInfo({
        id: this.id,
      });
      if (res.code == 1) {
      
        this.info = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.boxz {
  width: 1199px;
  margin: 0 auto;
  padding: 60px 0px 237px;

  .ttis {
    font-family: "Arial";
    font-weight: bold;
    font-size: 30px;
    color: #000000;
    width: 906px;
  }
  .artice {
    margin-top: 94px;
    font-family: "Arial";
    font-weight: 400;
    font-size: 21px;
    color: #333333;
    line-height: 32px;
  }
}
.bg_box {
  position: relative;
  .tiw {
    font-family: "Arial";
    font-weight: bold;
    font-size: 35px;
    color: #ffffff;
    line-height: 58px;
    position: absolute;
    left: 38%;
    top: 60%;
    // text-align: center;
    transform: translate(-50%, -50%);
  }
}
.bgs {
  width: 100%;
  height: 573px;
}
</style>
