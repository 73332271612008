<template>
  <div>
    <div class="box">
      <div class="box2">
        <img src="../assets/image/1.png" alt="" />
      </div>
      <div class="bix1">2222</div>

      <div class="box3">3333</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "XiaofangFrontBeacon",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style scoped lang="less">
.box4 {
  // top: 40px;
  // position: absolute;
}
img {
  width: 100%;
  height: 600px;
}

.box {
  height: 2000px;
  text-align: center;

  .box2 {
    height: 600px;
  }
  .bix1 {
    position: sticky;
    top: 0px;
    margin-top: 50px;
    z-index: 100;
    width: 50px;
    height: 50px;
    background: #f00;
  }
  .box3 {
    width: 100px;
    height: 100px;
    background: yellow;
    margin-top: -90px;
    height: 180px;
  }
}
</style>
